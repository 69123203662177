
export default {
  props: {
    label: {
      type: String,
      default: 'Button',
    },
    url: {
      type: String,
      default: '#',
    },
    icon: {
      type: String,
      default: 'play_filled',
    },
    iconSize: {
      type: String,
      default: '',
    },
    buttonId: {
      type: String,
    },
    buttonClass: {
      type: String,
    },
  },
}
