import { render, staticRenderFns } from "./TabSlider.vue?vue&type=template&id=6076efc0"
import script from "./TabSlider.vue?vue&type=script&lang=js"
export * from "./TabSlider.vue?vue&type=script&lang=js"
import style0 from "./TabSlider.vue?vue&type=style&index=0&id=6076efc0&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Markdown: require('/srv/app/components/markdown/Markdown.vue').default,Button: require('/srv/app/components/button/Button.vue').default,Button: require('/srv/app/components/button/Button.vue').default,TabBottom: require('/srv/app/components/tab/TabBottom.vue').default,ButtonWhatsapp: require('/srv/app/components/button/ButtonWhatsapp.vue').default,ButtonContactUs: require('/srv/app/components/button/ButtonContactUs.vue').default})
