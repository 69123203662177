import { render, staticRenderFns } from "./ProductFinder.vue?vue&type=template&id=16bdaac6"
import script from "./ProductFinder.vue?vue&type=script&lang=js"
export * from "./ProductFinder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/srv/app/components/button/Button.vue').default,CardDefault: require('/srv/app/components/card/CardDefault.vue').default,CardProduct: require('/srv/app/components/card/CardProduct.vue').default,CardIconLink: require('/srv/app/components/card/CardIconLink.vue').default,Button: require('/srv/app/components/button/Button.vue').default,Tab: require('/srv/app/components/tab/Tab.vue').default,ButtonLink: require('/srv/app/components/button/Link.vue').default})
