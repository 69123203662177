
export default {
  props: {
    number: {
      type: String,
      default: '300',
    },
  },
  // data() {
  //   return {
  //     displayNumber: 0,
  //     interval: false,
  //   }
  // },
  // ready() {
  //   this.displayNumber = this.number ? this.number : 0
  // },
  // beforeMount() {
  //   this.animateCounter()
  // },
  // methods: {
  //   animateCounter() {
  //     clearInterval(this.interval)

  //     if (this.number === this.displayNumber) {
  //       return
  //     }

  //     this.interval = window.setInterval(
  //       function () {
  //         if (this.displayNumber !== this.number) {
  //           let change = (this.number - this.displayNumber) / 3

  //           change = change >= 0 ? Math.ceil(change) : Math.floor(change)

  //           this.displayNumber = this.displayNumber + change
  //         }
  //       }.bind(this),
  //       50
  //     )
  //   },
  // },
}
