
export default {
  name: 'SectionsProductFinder',
  props: {
    sectionProductFinder: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMobile: false,
      tabIndex: 0,
      accordionIndex: null,
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.breakpoints, {
        passive: true,
      })
    }
  },
  mounted() {
    this.breakpoints()
    window.addEventListener('resize', this.breakpoints, {
      passive: true,
    })
  },
  methods: {
    breakpoints() {
      this.isMobile = window.innerWidth < 992
    },
    tab(index) {
      this.tabIndex = index
    },
    accordion(index) {
      this.accordionIndex === index
        ? (this.accordionIndex = null)
        : (this.accordionIndex = index)
    },
  },
}
