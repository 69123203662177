
export default {
  name: 'CardTestimonial',
  props: {
    cardTestimonial: {
      type: Object,
      default: () => {},
    },
    cssCols: {
      type: String,
      default: 'col-md-12'
    }

  },
}
