
export default {
  name: 'CardNumber',
  props: {
    cardNumber: {
      type: Object,
      default: () => {},
    },
  },
}
