
export default {
  props: {
    sectionsTestimonialStories: {
      type: Object,
      default: () => {},
    },
    // isMobile: {
    //   type: Boolean,
    //   default: false,
    // },
    // tabIndex: {
    //   type: Number,
    //   default: 0,
    // },
    // groupItemsBy: {
    //   type: Number,
    //   default: 3,
    // },
    
  },
  methods: {
    breakpoints() {
      this.isMobile = window.innerWidth < 992
    },
    tab(index) {
      this.tabIndex = index
    },
    chunk(array, size) {
    return Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
      array.slice(i * size, i * size + size)
    );
  },
  },
  data() {
    return {
       isMobile: false,
       tabIndex: 0,
       groupItemsBy: 3,
      /*
      testimonialSliderSettings: {
        arrow: true,
        dots: false,
        infinite: false,
        autoplay: false,
        swipeToSlide: true,
        slidesToScroll: 3,
        slidesToShow: 3,
        accessibility: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.1,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.1,
              slidesToScroll: 1,
            },
          },
        ],
      },*/

    }
  },
}
