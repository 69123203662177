// mixins/OrganizationSchema.js
export default {
    head() {
      return {
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://www.mikro.com.tr",
              "name": "Mikro Yazılımevi A.Ş.",
              "logo":"https://uploads.mikro.com.tr/mikro_logo_with_mikrogroup_4f4ad65436.png" ,
              "image":"https://uploads.mikro.com.tr/mikro_logo_with_mikrogroup_4f4ad65436.png" ,
              "description": "Mikro Yazılımevi A.Ş.",
              "telephone": "08502251010",
              "email": "info@mikro.com.tr",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Maslak Mah.Aos 55. Sk. 42 Maslak A Blok Sitesi No: 2 İç Kapı No: 11 Sarıyer  İstanbul",
                "addressLocality": "İstanbul",
                "addressCountry": "TR",
                "addressRegion": "Turkey",
                "postalCode": "34000"
              }
            }
          }
        ]
      };
    }
  }