import { render, staticRenderFns } from "./TestimonialStories.vue?vue&type=template&id=1e29bacf"
import script from "./TestimonialStories.vue?vue&type=script&lang=js"
export * from "./TestimonialStories.vue?vue&type=script&lang=js"
import style0 from "./TestimonialStories.vue?vue&type=style&index=0&id=1e29bacf&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UtilHeadingAdvanced: require('/srv/app/components/util/HeadingAdvanced.vue').default,CardNumber: require('/srv/app/components/card/CardNumber.vue').default,CardTestimonial: require('/srv/app/components/card/CardTestimonial.vue').default,Button: require('/srv/app/components/button/Button.vue').default,TabBottom: require('/srv/app/components/tab/TabBottom.vue').default,ButtonLink: require('/srv/app/components/button/Link.vue').default})
