
export default {
  name: 'SectionsVideo',
  props: {
    testimonialVideo: {
      type: Object,
      default: () => { },
    },
    testimonialVideoThumbnail: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return { showModal: false }
  }
}
