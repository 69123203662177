
export default {
  name: 'CardProduct',
  props: {
    cardProduct: {
      type: Object,
      default: () => {},
    },
  },
}
