
export default {
  name: 'SectionsOverviewBox',
  props: {
    sectionOverviewBox: {
      type: Object,
      default: () => {},
    },
  },
}
