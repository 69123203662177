import { render, staticRenderFns } from "./CardFeature.vue?vue&type=template&id=4b6f00b8"
import script from "./CardFeature.vue?vue&type=script&lang=js"
export * from "./CardFeature.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/srv/app/components/icon/Icon.vue').default,IconPrinciple: require('/srv/app/components/icon/IconPrinciple.vue').default})
