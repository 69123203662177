
export default {
  name: 'CardDefaultIcon',
  props: {
    cardDefaultIcon: {
      type: Object,
      default: () => {},
    },
  },
}
