
export default {
  name: 'SectionsCardsNumbers',
  props: {
    sectionCardsNumbers: {
      type: Object,
      default: () => {},
    },
  },
}
