
export default {
  props: {
    businessPartnerBanner: {
      type: Object,
      default: () => {
        return { text: String, image: Object, link: Object }
      },
    },
  },
}
