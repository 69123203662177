
export default {
  name: 'CardDefault',
  props: {
    cardDefault: {
      type: Object,
      default: () => {},
    },
  },
}
