import AllBanners from '@/banners.json'
export default {
  data() {
    return {
      bannerMoreInfo: {},
      bannerProductFinder: {},
      bannerCallUs: {},
      bannerProductCompare: {},
      bannerBusinessPartner: {},
      bannerCampaign: {},
    }
  },
  async mounted() {
    await this.getBanners()
  },
  methods: {
    getBanners() {
      if (this.page.details) {
        // All banners that any page contains.
        const pageBanners = this.page.details.filter(
          (item) => item.__component === 'site.banner'
        ).map((item) => {
          return item.banner.bannerType
        })

        // All Banners from collection: /banners
        const allBanners = AllBanners.map((item) => {
          return item.bannerType
        })

        // Matched bannerTypes of banners.
        const matchedBanners = allBanners.filter((e) =>
          pageBanners.includes(e)
        )

        // Bind Matched Banners to data.
        for (const banner of AllBanners) {
          for (const matched of matchedBanners) {
            if (banner.bannerType === matched) {
              // These bannerTypes are defined to the CMS by the development team.
              switch (banner.bannerType) {
                case 'moreInfoBanner':
                  this.bannerMoreInfo = banner
                  continue
                case 'productFinderBanner':
                  this.bannerProductFinder = banner
                  continue
                case 'callUsBanner':
                  this.bannerCallUs = banner
                  continue
                case 'productCompareBanner':
                  this.bannerProductCompare = banner
                  continue
                case 'businessPartnerBanner':
                  this.bannerBusinessPartner = banner
                  break;
                case 'campaignBanner':
                  this.bannerCampaign = banner
                  break;
              }
            }
          }
        }
      }
    }
  }
}
