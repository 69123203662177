import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=385be2b6"
import script from "./Testimonials.vue?vue&type=script&lang=js"
export * from "./Testimonials.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UtilTestimonialLogos: require('/srv/app/components/util/TestimonialLogos.vue').default,SectionsVideo: require('/srv/app/components/sections/Video.vue').default,UtilTestimonialStories: require('/srv/app/components/util/TestimonialStories.vue').default})
