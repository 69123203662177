
import SEO from '../mixins/SEO.js'
import Banners from '../mixins/banners.js'
import OrganizationSchema from '../mixins/OrganizationSchema.js'

export default {
  
  mixins: [SEO, Banners,OrganizationSchema],
  layout: 'home',
  head() {
    const seoHead = SEO.head.call(this);
    const orgHead = OrganizationSchema.head.call(this);

    const combinedHead = {
      ...seoHead,
      ...orgHead,
    };

    return combinedHead;
  },
  
  data() {
    return {
      page: {},
      seo: {
        title: '',
        description: '',
        keywords: '',
        shareImage: '',
        canonical: '',
        content: '',
        BirincilKampanya: '',
        preventIndexing: '',
        sitemap: '',
      },
      components: [],
    }
  },
  async fetch() {
    const { data } = await this.$axios.get('/home')

    this.page = data
    // SEO
    this.seo = data.SEO ? data.SEO : data;

    this.components = data.details.filter((item) => item.__component)
  },
}
