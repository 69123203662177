
export default {
  name: 'SectionsCardsAction',
  props: {
    sectionCardsAction: {
      type: Object,
      default() {},
    },
    colClass: {
      type: String,
      default: 'col-md-6'
    }
  },
}
