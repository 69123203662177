
export default {
  name: 'CardIconLink',
  props: {
    cardIconLink: {
      type: Object,
      default: () => {},
    },
  },
}
