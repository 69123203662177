
// import UTILS from '@/mixins/utils'
// import CloseForm from '@/mixins/close-form.js'
// mixins : [CloseForm, UTILS],

export default {
  name: 'TabSlider',
  props: {
    
    promo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMobile: false,
      tabIndex: 0,
  
      showActionButtons: true,
      // documentTitle: '',
      autoSlideInterval: null,
      autoSlideDelay: 5000, // 5 seconds delay between auto-slides
      userInteracted: false,
    }
  },
  beforeMount() {
    // this.documentTitle = document.title
  },
  mounted() {
    this.breakpoints()
    window.addEventListener('resize', this.breakpoints, {
      passive: true,
    })
    // Start auto-sliding after user interaction
    // document.addEventListener('click', this.startAutoSlide, { once: true })
    this.startAutoSlide();
  },
  beforeDestroy() {
    // Clear the interval when component is destroyed
    this.stopAutoSlide()
    window.removeEventListener('resize', this.breakpoints)
  },
  methods: {
   
    breakpoints() {
      this.isMobile = window.innerWidth < 992
    },
    tab(index) {
      this.tabIndex = index
      this.resetAutoSlide()
    },
    startAutoSlide() {
      this.userInteracted = true
      this.resetAutoSlide()
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval)
    },
    resetAutoSlide() {
      this.stopAutoSlide()
      if (this.userInteracted) {
        this.autoSlideInterval = setInterval(this.goToNextTab, this.autoSlideDelay)
      }
    },
    goToNextTab() {
      const nextIndex = (this.tabIndex + 1) % this.promo.length
      this.tab(nextIndex)
    },
  },
}
