import { render, staticRenderFns } from "./ButtonWhatsapp.vue?vue&type=template&id=1abeebe6&scoped=true"
import script from "./ButtonWhatsapp.vue?vue&type=script&lang=js"
export * from "./ButtonWhatsapp.vue?vue&type=script&lang=js"
import style0 from "./ButtonWhatsapp.vue?vue&type=style&index=0&id=1abeebe6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1abeebe6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/srv/app/components/icon/Icon.vue').default})
