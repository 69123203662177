import { EventBus } from '@/utils/event-bus.js';

export default {
    methods: {
        
        addGlobalEventListeners() {
        if (process.client) {
            // Your code that uses `document`...
             const triggers = document.querySelectorAll('.contact-form-trigger');
             triggers.forEach(trigger => {
             trigger.addEventListener('click', () => {
                 EventBus.$emit('open-modal');
                   });
             });
        
        }
            
        
            
        },

    }
  }