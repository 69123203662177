
import globalEventListeners from '@/mixins/globalEventListeners';

export default {
  mixins: [globalEventListeners],
  props: {
    moreInfoBanner: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // console.log("created")
    this.addGlobalEventListeners();

    
  },
}
