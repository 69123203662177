import { render, staticRenderFns } from "./CardsNumbers.vue?vue&type=template&id=4da69fa0"
import script from "./CardsNumbers.vue?vue&type=script&lang=js"
export * from "./CardsNumbers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardInfoNumber: require('/srv/app/components/card/CardInfoNumber.vue').default,ButtonLink: require('/srv/app/components/button/Link.vue').default})
