
export default {
  name: 'HeadingAdvanced',
  props: {
    heading: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
}
